import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import feedBackBg from "@/public/feedBackBg.webp";
import OurMessengers from "@/lib/ui/blocks/common/OurMessengers/OurMessengers";
import styles from "./FeedBackModal.module.scss";
import cl from "classnames";
import { RequestCallNew } from "@/lib/ui/components/Forms/RequestCallNew";
import { H2, H3, P3 } from "@/lib/ui/components/Typography";
import { SvgFBMessengerFill, SvgTelegram, SvgWhatsapp } from "../../icons";
import Link from "next/link";
import { PDF } from "../../../types/pdf";
import { LeadType } from "../../../types/lead-type";

interface Props {
  title?: string;
  subTitle?: string;
  agreementText?: string;
  buttonLabel?: string;
  leadType?: LeadType;
  excludeText?: boolean;
  url?: string;
  comment?: string;
  sourceForm?: string;
  pdf?: PDF;
  eventName: string[];
}

export const FeedBackModal = ({
  title = "getExpert",
  subTitle = "ourManagerWillCallYou",
  agreementText = "byClickingTheSendRequest",
  buttonLabel = "requestACall",
  leadType = "NOT_SPECIFIED",
  excludeText,
  url,
  sourceForm,
  comment,
  pdf,
  eventName,
}: Props) => {
  const { t } = useTranslation("index");
  return (
    <div className={styles.feedBackModal}>
      <div
        className={styles.feedbackBg}
        style={{ backgroundImage: `url(${feedBackBg.src})` }}
      />
      <div className={styles.feedbackAnimation}>
        <Image
          sizes="(max-width: 996px) 591px, 755px"
          src={"/images/complex/leaf/white-blue-leaf.webp"}
          alt={""}
          className={styles.feedbackFlowerRight}
          fill
        />
      </div>
      <div className={styles.feedbackContent}>
        <div className={styles.feedBackFormWrapper}>
          <div>
            <H2 className={styles.feedbackTitle}>
              {t(`form.titles.${title}`) || (
                <>
                  {t("form.titles.orderAPersonalSelection")} <br />
                  {t("form.titles.orderAPersonalSelection2")}
                </>
              )}
            </H2>

            {!excludeText && (
              <P3 className={cl(styles.feedBackText)}>
                {t(`form.subTitles.${subTitle}`)}
              </P3>
            )}
          </div>
          <RequestCallNew
            theme="white"
            sourceForm={sourceForm}
            className={styles.feedBackForm}
            agreementText={agreementText}
            buttonLabel={buttonLabel}
            leadType={leadType}
            url={url}
            comment={comment}
            pdf={pdf}
            eventName={eventName}
          />
        </div>
        <div className={styles.messengerDesctop}>
          <OurMessengers />
        </div>
        <div className={styles.messengerMobile}>
          <H3 className={styles.messengerMobile__title}>
            {t("contactUsThroughOurMessengers")}
          </H3>
          <div className={styles.messengerMobile__cardList}>
            <Link
              href={"https://t.me/VillaCarte_Sales"}
              className={styles.messengerMobile__card}
              target="_blank"
            >
              <SvgTelegram />
            </Link>
            <Link
              href={"https://wa.me/66892900110"}
              className={styles.messengerMobile__card}
              target="_blank"
            >
              <SvgWhatsapp />
            </Link>
            <Link
              href={"https://www.facebook.com/messages/t/429349140601777"}
              className={styles.messengerMobile__card}
              target="_blank"
            >
              <SvgFBMessengerFill />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
