import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "./ContactFormTeam.module.scss";
import { Input } from "../../Input";
import { validateRequired, validations } from "@/lib/utils/validateForm";
import { Button } from "../../Buttons";
import { useTranslation } from "next-i18next";
import InputSelect from "@/lib/ui/components/Selects/InputSelect";
import { Textarea } from "@/lib/ui/components/Textarea";
import { IFeedback } from "@/lib/types/feedback";
import { postFeedback } from "@/lib/api/feedback";
import { P1, P4 } from "../../Typography";
import { useRouter } from "next/router";
import { modal } from "@/lib/ui/components/Modal";
import { BASE_FRONT_URL } from "../../../../api/global";
import cl from "classnames";
import { IClassname } from "../../../../types/classname";
import { sendGTMEvent } from "@next/third-parties/google";

const communicationOptions = [
  { value: "call", label: "select.phoneCall" },
  { value: "telegram", label: "select.telegram" },
  { value: "whatsapp", label: "select.whatsApp" },
  { value: "facebook", label: "select.facebookMessenger" },
];

type IContactForm = {
  name: string;
  phone: string;
  category?: { value: string; label: string };
  communicationWay?: { value: string; label: string };
  comment: string;
};

interface ContactFormProps extends IClassname {
  sourcePage?: string;
  title?: string;
  isModal?: boolean;
  employee?: string;
  commentsPlaceholder?: string;
  eventName: string[];
}

export const ContactFormTeam: FC<ContactFormProps> = ({
  title,
  isModal = false,
  employee,
  eventName,
}) => {
  const { t, i18n: { language } } = useTranslation("index");
  const route = useRouter();
  const sourcePage = `${BASE_FRONT_URL}/${route.locale}${route.asPath}`;
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    control,
  } = useForm<IContactForm>({ mode: "onSubmit" });

  const [firstInteraction, setFirstInteraction] = useState(true);

  useEffect(() => {
    if (isDirty && firstInteraction) {
      eventName.map(name => {
        sendGTMEvent(
          {
            event: 'ga_event',
            eventName: `form_start_${name}`,
            eventType: 'click',
            pageUrl: sourcePage,
          },
        );
      })
      
      setFirstInteraction(false);
    }
  }, [isDirty, firstInteraction, sourcePage, eventName]);

  const onSubmit = async (data: IContactForm) => {
    const leadType = data.category ? data.category.value : "NOT_SPECIFIED";
    const comment = `${
      data.communicationWay ? `communication: ${data.communicationWay.value},` : 'communication: call,'
    } вопрос: ${data.comment} ${
      data.category ? `, service: ${data.category.value}` : ""
    }, ${employee ? employee : ""}`;

    const sentData = {
      name: data.name,
      phone: data.phone.replaceAll(/\(|\)|\s|\-/gi, ""),
      leadType,
      comment,
      sourcePage,
      sourceForm: "about-team-form",
      language,
    } as IFeedback;
    try {
      await postFeedback({ ...sentData });
      eventName.map(name => {
        sendGTMEvent(
          {
            event: 'ga_event',
            eventName: `form_submit_${name}`,
            eventType: 'form',
            pageUrl: sourcePage,
          },
        );
      })
    } catch (e) {
      console.log(e);
    }
    route.push("/thanks");
    isModal && modal.close();
  };
  return (
    <div className={cl(styles.contactForm, isModal && styles['contactForm--modal'])}>
      {title && <P1 className={styles.contactForm_title}>{title}</P1>}
      <form
        className={styles.contactForm_form}
        onSubmit={handleSubmit(onSubmit)}
        id="contactForm"
      >
        <Input
          theme={"white"}
          content="name"
          placeholder="yourName"
          error={errors.name}
          getValue={getValues}
          required
          {...register("name", {
            ...validateRequired(validations.name),
            required: true,
          })}
          autoComplete="name"
        />
        <Input
          theme={"white"}
          content="phone"
          placeholder="phoneNumber"
          error={errors.phone}
          required
          type="tel"
          {...register("phone", {
            ...validateRequired(validations.phone),
            required: true,
          })}
          inputMode="numeric"
          getValue={getValues}
          autoComplete="phone"
          maxLength={20}
        />
        <Controller
          name="communicationWay"
          control={control}
          render={({ field, fieldState }) => (
            <InputSelect
              {...field}
              error={fieldState.error}
              placeholder={t("form.select.chooseConvenientCommunication")}
              options={communicationOptions.map((option) => ({
                ...option,
                label: t('form.' + option.label),
              }))}
            />
          )}
        />
        <Textarea
          wrapperClassName={styles.textarea}
          theme={"white"}
          placeholder={t("form.enterYourQuestion")}
          error={errors.comment}
          required
          {...register("comment", {
            ...validateRequired({}),
            required: true,
          })}
          autoComplete="off"
        />
        <Button
          as="button"
          label={t("form.buttons.submit")}
          theme={"dark-invert"}
          size={"md"}
          type="submit"
          className={styles.questionButton}
        />
         <P4 className={styles.agreement}>
          {t("form.agreements.agreementSubmit")}
        </P4>
      </form>
    </div>
  );
};
