/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    locales: ["default", "en", "ru"],
    defaultLocale: "default",
    localeDetection: false,
  },
  defaultNS: ["index"],
  
  serializeConfig: false, // because of the custom use i18next plugin
};
