import { FC, ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import cl from "classnames";
import styles from "./DownloadPresentation.module.scss";
import { postFeedback } from "@villa/api/feedback";
import { Input } from "../../Input";
import { IRequestCallForm } from "@/lib/types/forms/request-call";
import { validateRequired, validations } from "@/lib/utils/validateForm";
import { Button } from "../../Buttons";
import { useTranslation } from "next-i18next";
import { IFeedback } from "@/lib/types/feedback";
import { H2, P3, P4 } from "../../Typography";
import { useRouter } from "next/router";
import { BASE_FRONT_URL } from "../../../../api/global";
import { downloadLink } from "../../../../utils/downloadLink";
import { sendGTMEvent } from "@next/third-parties/google";
import { openThanksPage } from "../../../../utils/openThanksPage";

interface IDownloadPresentation {
  theme?: "blue" | "white" | "transparent" | "white-on-blue";
  className?: string;
  agreementText?: string;
  title?: string;
  buttonLabel?: string;
  presentation: { link: string; title: string } | null;
  downloadText?: string;
  downloadTitle?: string;
  image?: ReactNode;
  selectionTitle?: string;
  comment?: string;
  eventName: string[];
}

export const DownloadPresentation: FC<IDownloadPresentation> = ({
  theme = "white",
  title,
  className,
  buttonLabel = "downloadPresentantion",
  presentation,
  agreementText = "agreementPresentation",
  downloadText,
  downloadTitle,
  selectionTitle,
  comment,
  eventName,
}) => {
  const router = useRouter();
  const { t, i18n: {language} } = useTranslation("index");
  const sourcePage = `${BASE_FRONT_URL}/${router.locale}${router.asPath}`;
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
  } = useForm<IRequestCallForm>({ mode: "onSubmit" });

  const [firstInteraction, setFirstInteraction] = useState(true);

  useEffect(() => {
    if (isDirty && firstInteraction) {
      eventName.map(name => {
        sendGTMEvent(
          {
            event: 'ga_event',
            eventName: `form_start_${name}`,
            eventType: 'click',
            pageUrl: sourcePage,
          },
        );
      })
      
      setFirstInteraction(false);
    }
  }, [isDirty, firstInteraction, sourcePage, eventName]);

  const onSubmit = async (data: IRequestCallForm) => {
    const sentData = {
      name: data.name,
      phone: data.phone.replaceAll(/\(|\)|\s|\-/gi, ""),
      leadType: "SALE",
      sourceForm: "top property selection",
      sourcePage,
      comment: selectionTitle ? t(selectionTitle) : comment ? comment : "",
      language,
    } as IFeedback;
    try {
      await postFeedback({ ...sentData });
      eventName.map(name => {
        sendGTMEvent(
          {
            event: 'ga_event',
            eventName: `form_submit_${name}`,
            eventType: 'form',
            pageUrl: sourcePage,
          },
        );
      })
    } catch (e) {
      console.log(e);
    }
    if (presentation) {
      downloadLink(presentation.link, downloadTitle || presentation.title);
      window.open(presentation.link, "_blank");
    } else {
      openThanksPage(language)
    }
  };

  return (
    <form
      className={cl(styles.download, className, {
        [styles["download--transparent"]]: theme === "transparent",
        [styles["download--white"]]: theme === "white",
        [styles["download--white-on-blue"]]: theme === "white-on-blue",
        [styles["download--blue"]]: theme === "blue",
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      {title && <H2 className={styles.download__title}>{title}</H2>}
      <P3 className={styles.download__text}>
        {downloadText ?? t("form.subTitles.fillTheDownloadForm")}
      </P3>
      <Input
        theme={theme}
        content="name"
        placeholder="yourName"
        error={errors.name}
        getValue={getValues}
        required
        {...register("name", {
          ...validateRequired(validations.name),
          required: true,
        })}
        autoComplete="name"
      />
      <Input
        theme={theme}
        content="phone"
        placeholder="phoneNumber"
        error={errors.phone}
        required
        type="tel"
        {...register("phone", {
          ...validateRequired(validations.phone),
          required: true,
        })}
        inputMode="numeric"
        getValue={getValues}
        autoComplete="phone"
        maxLength={20}
      />
      <P4 className={styles.download__agreement}>
        {t(`form.agreements.${agreementText}`)}
      </P4>
      <Button
        as="button"
        type="submit"
        label={t(`form.buttons.${buttonLabel}`)}
        theme={
          theme === "blue" || theme === "transparent"
            ? "light-invert"
            : theme === "white-on-blue"
            ? "light"
            : "dark-invert"
        }
        size={"lg"}
        onSubmit={handleSubmit(onSubmit)}
      />
    </form>
  );
};
