import { IFeedback } from "@villa/types/feedback";
import { Cookies } from "react-cookie";
import { agent } from "./agent";
import { LEAD_SERVICE_URL } from "./global";

export const postFeedback = async (postData: IFeedback) => {
  const cookie = new Cookies();
  const utmCookie = cookie.get("utm") || {};
  const googleClientID = cookie.get("_ga")?.slice(6) || "";
  return await agent.post(LEAD_SERVICE_URL, {
    ...postData,
    ...utmCookie,
    language: postData.language.toUpperCase(),
    googleClientID,
    project: "Villacarte",
  });
};
