import { SVGFbMessengerColor, SVGTelegramColor, SVGWhatsappColor } from '../../icons';
import styles from './Messengers.module.scss';
import Link from "next/link";
import { sendMessClick } from "@/lib/utils/sendGTM";
import cn from 'classnames';
import { FC } from 'react';

type MessengersProps = {
    className?: string;
}

export const Messengers: FC<MessengersProps> = ({className}) => {
    return (
        <div className={cn(styles.messengers, className)}>
            <Link
                href={"https://wa.me/66892900110"}
                target="_blank"
                className={styles.messengers__link}
                onClick={sendMessClick}
            >
                <SVGWhatsappColor />
                <div className={styles.messengers__shadow}/>
            </Link>
            <Link
                href={"https://t.me/VillaCarte_Sales"}
                target="_blank"
                className={styles.messengers__link}
                onClick={sendMessClick}
            >
                <SVGTelegramColor />
                <div className={styles.messengers__shadow}/>
            </Link>   
            <Link
                href={"https://www.facebook.com/messages/t/429349140601777"}
                target="_blank"
                className={styles.messengers__link}
                onClick={sendMessClick}
            >
                <SVGFbMessengerColor />
                <div className={styles.messengers__shadow}/>
            </Link> 
        </div>
    )
}