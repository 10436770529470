import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";

export const useUtmCookies = () => {
  const [cookie, setCookie, removeCookie] = useCookies(["utm"]);
  const utmCookie = cookie.utm;
  const router = useRouter();
  const query = useMemo(() => router.query, [router]);
  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = query;

  useEffect(() => {
    if (!router.isReady) return;

    if (utmCookie && JSON.stringify(utmCookie) === "{}") {
      removeCookie("utm");
    }
    if (!utmCookie) {
      const todayPlus30Days = new Date();
      todayPlus30Days.setDate(todayPlus30Days.getDate() + 30);
      const extractedTags = {
        utmCampaign: utm_campaign,
        utmContent: utm_content,
        utmMedium: utm_medium,
        utmSource: utm_source,
        utmTerm: utm_term,
      };
      const value = JSON.stringify(extractedTags);
      if (value !== "{}") {
        setCookie("utm", value, { expires: todayPlus30Days });
      }
    }
    if (utm_campaign || utm_content || utm_medium || utm_source || utm_term) {
      setTimeout(() => {
        delete query.utm_source;
        delete query.utm_medium;
        delete query.utm_campaign;
        delete query.utm_term;
        delete query.utm_content;
        router.replace({ pathname: router.pathname, query: { ...query } }, undefined, {
          shallow: true,
          scroll: false,
        });
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setCookie,
    utmCookie,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
    query,
    router.isReady,
  ]);
};
