export const isDevelopment = process.env.NEXT_PUBLIC_NODE === 'development' || process.env.NODE_ENV === 'development';
export const isLocal = process.env.NEXT_PUBLIC_LOCAL === 'local'

export const BASE_SERVICE_URL = `${process.env.NEXT_PUBLIC_SERVICE_PROTOCOL || "https"}://${process.env.NEXT_PUBLIC_SERVICE_URL || "villacarte.com"}/api`;

export const LEAD_SERVICE_URL = `https://${process.env.NEXT_PUBLIC_LEAD_SERVICE_URL || "verde-back.layanview.com"}/api/lead`;

export const ADMIN_URL = `https://${process.env.NEXT_PUBLIC_ADMIN_URL || "admin.villacarte.com"}`

export const BASE_FRONT_URL = `https://${process.env.NEXT_PUBLIC_URL || "villacarte.com"}`;

export const BASE_STORE_URL = `https://${process.env.NEXT_PUBLIC_STORE_URL || "villacarte.com"}`;