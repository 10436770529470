import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import cl from "classnames";
import styles from "./RequestCallNew.module.scss";
import { postFeedback } from "@villa/api/feedback";
import { Input } from "../../Input";
import { IRequestCallForm } from "@/lib/types/forms/request-call";
import { validateRequired, validations } from "@/lib/utils/validateForm";
import { Button, ButtonTheme } from "../../Buttons";
import { useTranslation } from "next-i18next";
import { IFeedback } from "@/lib/types/feedback";
import { H3, P1, P3, P4 } from "../../Typography";
import { useRouter } from "next/router";
import { modal } from "../../Modal";
import { BASE_FRONT_URL } from "../../../../api/global";
import { downloadLink } from "../../../../utils/downloadLink";
import { PDF } from "../../../../types/pdf";
import { sendGTMEvent } from "@next/third-parties/google";
import { LeadType } from "../../../../types/lead-type";
import { openThanksPage } from "../../../../utils/openThanksPage";

const buttonThemes: Partial<
  Record<"blue" | "white" | "transparent" | "accent", ButtonTheme>
> = {
  blue: "light",
  transparent: "light-invert",
  accent: "dark",
};

export interface IRequestCall {
  theme?: "blue" | "white" | "transparent" | "accent";
  className?: string;
  agreementText?: string;
  title?: string;
  h3title?: string;
  subTitle?: string;
  buttonLabel?: string;
  includeQuestion?: boolean;
  sourceForm?: string;
  leadType?: LeadType;
  url?: string;
  comment?: string;
  pdf?: PDF;
  eventName: string[];
  eventAction?: string;
  buttonClass?: string;
  isFeedBackModal?: boolean;
  reverseAgreementOrder?: boolean;
}

export const RequestCallNew: FC<IRequestCall> = ({
  theme = "white",
  title,
  h3title,
  subTitle,
  className,
  buttonLabel = "requestACall",
  agreementText = "byClickingTheSendRequest",
  includeQuestion,
  sourceForm = "request-call",
  leadType = "NOT_SPECIFIED",
  url,
  comment,
  pdf,
  eventName,
  eventAction,
  buttonClass,
  isFeedBackModal = false,
  reverseAgreementOrder,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("index", { keyPrefix: "form" });
  const router = useRouter();
  const sourcePage = `${BASE_FRONT_URL}/${router.locale}${router.asPath}`;
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
  } = useForm<IRequestCallForm>({ mode: "onSubmit" });

  const [firstInteraction, setFirstInteraction] = useState(true);

  useEffect(() => {
    if (isDirty && firstInteraction) {
      eventName.map((name) => {
        sendGTMEvent({
          event: "ga_event",
          eventName: `${eventAction ? eventAction + '_' : ''}form_start_${name}`,
          eventType: "click",
          pageUrl: sourcePage,
        });
      });

      setFirstInteraction(false);
    }
  }, [isDirty, firstInteraction, sourcePage, eventName, eventAction]);

  const onSubmit = async (data: IRequestCallForm) => {
    const sentComment = `${data.comment ? data.comment : ""} ${comment ? comment : ""}`;
    const sentData = {
      name: data.name,
      phone: data.phone.replaceAll(/\(|\)|\s|\-/gi, ""),
      sourcePage,
      sourceForm,
      leadType,
      comment: sentComment,
      language,
    } as IFeedback;
    try {
      await postFeedback({ ...sentData });
      eventName.map((name) => {
        sendGTMEvent({
          event: "ga_event",
          eventName: `${eventAction ? eventAction + '_' : ''}form_submit_${name}`,
          eventType: "form",
          pageUrl: sourcePage,
        });
      });
    } catch (e) {
      console.log(e);
    }
    modal.close();
    if (pdf) {
      downloadLink(pdf.link, pdf.title);
      window.open(pdf.link, "_blank");
    } else {
      openThanksPage(language, url)
    }
  };

  return (
    <form
      className={cl(styles.request, className, {
        [styles["request--transparent"]]: theme === "transparent",
        [styles["request--white"]]: theme === "white",
        [styles["request--accent"]]: theme === "accent",
        [styles["request--blue"]]: theme === "blue",
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      {theme === "transparent" && <div className={styles.request__bg}></div>}
      {title && <P1 className={styles.request__title}>{t(`titles.${title}`)}</P1>}
      {h3title && <H3 className={styles.request__title}>{t(`titles.${h3title}`)}</H3>}
      {subTitle && <P3 className={styles.request__subtitle}>{t(`subTitles.${subTitle}`)}</P3>}
      {sourceForm === "request_calculation" && (
        <Input
          theme={theme}
          content="question"
          placeholder="projectsName"
          error={errors.comment}
          required
          type="text"
          {...register("comment", {
            ...validateRequired(validations.question),
            required: true,
          })}
          getValue={getValues}
          autoComplete="off"
          maxLength={500}
          className={styles.request__input}
        />
      )}
      <Input
        theme={theme}
        content="name"
        placeholder="yourName"
        error={errors.name}
        getValue={getValues}
        required
        {...register("name", {
          ...validateRequired(validations.name),
          required: true,
        })}
        autoComplete="name"
      />
      <Input
        theme={theme}
        content="phone"
        placeholder="phoneNumber"
        error={errors.phone}
        required
        type="tel"
        {...register("phone", {
          ...validateRequired(validations.phone),
          required: true,
        })}
        getValue={getValues}
        autoComplete="tel"
        inputMode="tel"
        maxLength={20}
        className={styles.request__input}
      />
      {includeQuestion && (
        <Input
          theme={theme}
          content="question"
          placeholder="yourQuestion"
          error={errors.comment}
          required
          type="text"
          {...register("comment", {
            ...validateRequired(validations.question),
            required: true,
          })}
          getValue={getValues}
          autoComplete="off"
          maxLength={500}
          className={styles.request__input}
        />
      )}
      <div
        className={cl(
          styles.request__buttonAndAgreement,
          reverseAgreementOrder ? styles["request__buttonAndAgreement--reverse"] : "",
        )}
      >
        {!isFeedBackModal && (
          <P4 className={styles.request__agreement}>
            {t(`agreements.${agreementText}`)}
          </P4>
        )}
        <Button
          as="button"
          type="submit"
          label={t(`buttons.${buttonLabel}`)}
          theme={buttonThemes[theme] || "dark-invert"}
          size={"lg"}
          onSubmit={handleSubmit(onSubmit)}
          className={cl(styles.request__button, buttonClass || "")}
        />
        {isFeedBackModal && (
          <P4
            className={cl(
              styles.request__agreement,
              styles["request__agreement--bottom"],
            )}
          >
            {t(`agreements.${agreementText}`)}
          </P4>
        )}
      </div>
    </form>
  );
};
